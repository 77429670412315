import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {componentList} from '../../../api/global';
import {
  useContentDBSearch,
  useListingsSolrSearch,
  useListingsSolrMLTSearch
} from '../../../utils/hooks';
import {useState} from "react";
import {chooseRandomList} from '../../../api/global';

function ContentList(props) {
  let {
    comp, compProps, fetchOpts, pageCurr, perPage, noPager, random, noresult,
    spacing, justifyContent, colXs, colS, colMd, colLg, colXlg, solr_q
  } = props;

  if (!fetchOpts || !comp) {
    return null;
  }

  perPage = perPage || 0;
  spacing = spacing || 1;
  const DynamicComponent = componentList[comp];
  const [currPage, setCurrPage] = useState({
    curr: (pageCurr || 1),
    off: ((pageCurr && pageCurr > 1) ? (pageCurr * perPage) : 0)
  });
  let data, isLoading, isError, random_arr;

  //Math.round(12 / cols)
  colXs = colXs || 12;
  // colS = (!colS && colXs) ? colXs : colS;
  // colMd = (!colMd && colS) ? colS : colMd;
  // colLg = (!colLg && colMd) ? colMd : colLg;
  // colXlg = (!colXlg && colLg) ? colLg : colXlg;

  const handlePageClick = (event, val) => {
    let offset = val == 1 ? 0 : (val - 1) * perPage;
    setCurrPage({curr: val, off: offset});
  };

  if (fetchOpts.solrListings) {
    ({
      data,
      isLoading,
      isError
    } = useListingsSolrSearch(fetchOpts, perPage, currPage.off, solr_q));
  }
  else if (fetchOpts.solrMltListings) {
    ({
      data,
      isLoading,
      isError
    } = useListingsSolrMLTSearch(fetchOpts, perPage, currPage.off, solr_q));
  }
  else {
    ({data, isLoading, isError} = useContentDBSearch({
      ...fetchOpts,
      pageLimit: perPage,
      pageOffset: currPage.off
    }));
  }

  if (data?.listings && !data?.page_data) {
    data.page_data = data.listings;
  }

  if (data?.page_data?.length < 1 && !isLoading && !isError && !noresult) {
    return null;
  }

  if (isError && !isLoading) {
    return (
        <p>Network issue prevented the data from loading..</p>
    );
  }

  if (data?.page_data?.length > 0 && !isLoading && !isError) {

    let contentVar = null;
    if (data?.page_data !== undefined && Array.isArray(data?.page_data) && !isNaN(random)) {
      contentVar = chooseRandomList(data.page_data, random).map((node) => {
            return (
                <Grid item xs={colXs} sm={colS} md={colMd} lg={colLg} xl={colXlg}
                      key={node.id}>
                  <DynamicComponent key={`card-list-${node.internalId}`}
                                    node={node} {...compProps} />
                </Grid>
            );
          });
    }
    else if (data?.page_data !== undefined && Array.isArray(data?.page_data) && isNaN(random)) {
      contentVar = data.page_data.map((node) => {
        return (
            <Grid item xs={colXs} sm={colS} md={colMd} lg={colLg} xl={colXlg}
                  key={node.id}>
              <DynamicComponent key={`card-list-${node.internalId}`}
                                node={node} {...compProps} />
            </Grid>
        );
      });
    }

    return (
        <Grid container spacing={spacing} justifyContent={justifyContent}>
          {contentVar}
          {!noPager && data && data.ttlPages && parseInt(data.ttlPages) > 1 &&
            <Grid item xs={12}>
              <Box mt={4} align="center">
                <Pagination count={parseInt(data.ttlPages) || 10} page={currPage.curr}
                            size="small" siblingCount={2} color="primary"
                            variant="outlined" shape="rounded"
                            onChange={(ev, val) => handlePageClick(ev, val)}/>
              </Box>
            </Grid>
          }
        </Grid>
    );
  }

  if (!data?.page_data?.length && !isLoading && !isError && noresult) {
    return noresult;
  }

  return null;
}

ContentList.propTypes = {
  fetchOpts: PropTypes.shape().isRequired,
  comp: PropTypes.string.isRequired,
  solr_q: PropTypes.string,
  perPage: PropTypes.number,
  pageCurr: PropTypes.number,
};

export default ContentList;
