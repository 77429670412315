import { useState, useEffect } from "react";
import useSWR from "swr";
import * as listingApi from "../api/listing";
import {fetchAPIRequestData, drupalUserAccountActions} from "../api/global";
import {getSolrClient} from '../utils/solr';

export function useListingsSolrSearch(list_obj, pageLimit, pageOffset, q) {
  const solrClient = getSolrClient();
  const new_query = q ? q : solrClient.assembleQuery(list_obj);
  const {
    data,
    error
  } = useSWR(['/solr', pageLimit, pageOffset, new_query], listingApi.listingsSolrSearch);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function useListingsSolrMLTSearch(list_obj, pageLimit, pageOffset, q) {
  const solrClient = getSolrClient();

  const new_query = q ? q : solrClient.assembleQuery(list_obj);
  const {
    data,
    error
  } = useSWR(['/solr', pageLimit, pageOffset, new_query], listingApi.listingsSolrMLTSearch);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function useContentDBSearch(settings) {
  let {url, query, opts, pageLimit, pageOffset, fetcher} = settings;
  fetcher = fetcher || fetchAPIRequestData;

  const swrResp = useSWR([url, query, opts, pageLimit, pageOffset], fetcher, {});
  const {data, isValidating, revalidate, error} = swrResp;
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function useDrupalUserAccountOps(settings) {
  let {url, opts, params, fetcher} = settings;
  fetcher = fetcher || drupalUserAccountActions;
  let data, isValidating, revalidate, error;
  const swrResp = useSWR([url, opts, params], fetcher, {});
  ({data, isValidating, revalidate, error} = swrResp);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

// Lazy load when scrolled into view.
export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [])
  return isIntersecting;
}
