import React, {Fragment} from 'react';
import Container from '@material-ui/core/Container';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useContentDBSearch } from "../../../utils/hooks";
import { componentList } from '../../../api/global';
import CustomMuiButton from "../CustomMuiButton";
import ContentList from "../../02_molecules/ContentList";
import { Testimonial } from '../../02_molecules/Testimonial/Testimonial';

let DynamicCarouselComponent;

let fetchOpts = {
  url: ``,
  query: {},
  opts: { type: 'tld' },
};

const slickSettListingsCarousel = {
  slidesToShow: 4,
  slidesToScroll: 1,
  adaptiveHeight: true,
  speed: 500,
};

const FetchedDataInCarousel = (fetchOpts, comp, slickSett, slickProps) => {
  let DynamicComponent;
  slickProps = slickProps || {};

  if (comp == 'Testimonial') {
    DynamicComponent = Testimonial;
    DynamicCarouselComponent = componentList['TestimonialCarousel'];
  }
  // Simple list of images for Slick Carousel:
  else if (comp == 'Carousel') {
    DynamicCarouselComponent = componentList['CustomMuiMediaSlider'];
  }
  else {
    DynamicComponent = componentList[comp];
    DynamicCarouselComponent = componentList['CustomMuiCarousel'];
  }
  let { data, isLoading, isError } = useContentDBSearch({
    ...fetchOpts,
  });

  if (data?.page_data !== undefined && Array.isArray(data?.page_data)) {
    const thumbs = [];
    if (comp == 'Testimonial') {
      data.page_data.map((node, ind) => {
        let img = {
          src: '/sites/default/files/default_images/listing_placeholder.jpg',
          imgAlt: `Client review`
        };
        if (node?.imageOrig) {
          img = { src: node.imageOrig, imgAlt: `Client review` };
        }
        thumbs.push(img);
      });
    }

    if (comp == 'Testimonial' && thumbs.length > 0 && data && !isLoading && !isError) {
      return (<DynamicCarouselComponent key={`testim-slider-1`} contentThumbs={thumbs} showThumb={true}>
        {data.page_data.map((node) => {
          return (
              <DynamicComponent key={`node-${node.internalId}`} node={node} />);
        })
        }
      </DynamicCarouselComponent>);
    }
    else if (comp == 'Carousel' && Array.isArray(data?.page_data) && data?.page_data[0]?.src
        && !isLoading && !isError ) {
      return (<DynamicCarouselComponent key={`carousel-slider-1`}
                                        slickSett={slickSett}
                                        {...slickProps} slides={data?.page_data} />);
    }
    else if (!['Testimonial', 'Carousel'].includes(comp) && data && !isLoading && !isError) {
      return (<DynamicCarouselComponent key={`content-slider-1`}
                                        slickSett={slickSett}>
        {data.page_data.map((node) => {
          return (
              <DynamicComponent key={`node-${node.internalId}`} node={node} />);
        })
        }
      </DynamicCarouselComponent>);
    }
  }
  return null;
}

function ReactRESTView(props) {
  if (!(props?.block?.data)) {
    return null;
  }
  const block_data = props?.block?.data || '';
  const json_data = JSON.parse(block_data);
  let { rest_url, display, rest_view, view_id, per_page, entity_id } = json_data;
  let grid_cols = 3;

  if (props.skipData) {
    return (<div className={`rets-view-placeholder`}>Drupal View
      placeholder: {rest_view}</div>);
  }

  if (per_page) {
    const div = parseInt(per_page / 3);
    grid_cols = (grid_cols === div * 3) ? 4 : grid_cols;
  }

  let url_params = {};

  if (rest_url.includes('?')) {
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
        match;
    while (match = regex.exec(rest_url)) {
      url_params[match[1]] = match[2];
    }
    rest_url = rest_url.split('?')[0];
  }
  fetchOpts = { ...fetchOpts, url: rest_url, query: url_params };

  return (
    <Fragment>

      {/* RENDER VIEWS OF TYPE:    article_rest: */}
      {display && display.includes('article_rest:') &&
        <Container maxWidth="lg">
          <Box px={4} mb={4}>
            <Box pt={5} mb={5}>
              <Typography align="center" component="h2" variant="h2">OUR <strong>
                BLOG</strong></Typography>
            </Box>
            <ContentList fetchOpts={fetchOpts} colMd={4}
              comp={`EatonCardArticle`} />
            <Box align="center" py={8}>
              <CustomMuiButton color="primary" href={`/blog`}
                label={`READ ALL <strong>BLOGS</strong>`}></CustomMuiButton>
            </Box>
          </Box>
        </Container>
      }


      {/* RENDER VIEWS OF TYPE:    content:partner_logos: */}
      {display && display.includes('content:partner_logos') &&
        <div className={"box-shadow sponsor-slider"}>
          {
            FetchedDataInCarousel(fetchOpts, 'Carousel', {
              slidesToShow: 10,
              slidesToScroll: 1,
              dots: false,
              infinite: false,
              // centerMode: true,
              // centerPadding: "0px",
              speed: 600,
              responsive: [
                {
                  breakpoint: 1190,
                  settings: {
                    slidesToShow: 8,
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 6,
                  }
                },
                {
                  breakpoint: 790,
                  settings: {
                    slidesToShow: 5,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 3,
                  }
                }
              ]
            }, {
              showThumb: { lgDown: true },
              showLight: false,
              customClass: 'sponsor-carousel',
            })}
        </div>
      }

      {/* RENDER VIEWS OF TYPE:    listings_rest: */}
      {display && display.includes('listings_rest:') && display.includes('carousel') &&
        (
          FetchedDataInCarousel(fetchOpts, 'EatonListingCard', slickSettListingsCarousel)
        )
      }
      {display && display.includes('listings_rest:') && !display.includes('carousel') &&
        (
          <ContentList fetchOpts={fetchOpts} colMd={grid_cols}
                 comp={`EatonListingCard`} />
        )
      }

      {/* RENDER VIEWS OF TYPE:    agent_rest: */}
      {display && display.includes('agent_rest:') && display.includes('carousel') &&
        (
          FetchedDataInCarousel(fetchOpts, 'EatonAgentSlide', {
            slidesToShow: 3,
            slidesToScroll: 3,
            // adaptiveHeight: true,
            speed: 500,
            infinite: true,
          })
        )
      }
      {display && display.includes('agent_rest:') && !display.includes('carousel') && display.includes('all') &&
        <ContentList fetchOpts={fetchOpts} colXs={12} colS={6}
                   colMd={3} spacing={2} justifyContent={`center`}
                   comp={`EatonAgentCard`} />
      }
      {display && display.includes('agent_rest:') && !display.includes('carousel') && !display.includes('all')
        && !display.includes('staff_tags_rest') &&
        <ContentList fetchOpts={fetchOpts} random={4} colXs={12} colS={6}
                   colMd={6} spacing={2} justifyContent={`center`}
                   comp={`EatonAgentCard`} />
      }
      {display && display.includes('agent_rest:') && !display.includes('carousel') && !display.includes('all')
      && display.includes('staff_tags_rest') &&
        <ContentList fetchOpts={fetchOpts} colXs={12} colS={6}
                   colMd={3} spacing={2} justifyContent={`center`}
                   comp={`EatonAgentCard`} />
      }

      {/* RENDER VIEWS OF TYPE:    taxonomy_terms_rest: */}
      {display && display.includes('taxonomy_terms_rest:') && !display.includes('carousel') && display.includes('grid') &&
        <Container maxWidth="md">
          <ContentList fetchOpts={fetchOpts} colXs={12} spacing={2}
                       comp={`LocationBlockTeaser`} />
        </Container>
      }
      {display && display.includes('taxonomy_terms_rest:') && !display.includes('carousel') && !display.includes('grid') &&
        <Container maxWidth="md">
          <ContentList fetchOpts={fetchOpts} colXs={12} spacing={2}
                       comp={`LocationBlockList`} />
        </Container>
      }


      {/* RENDER VIEWS OF TYPE:    testimonials_rest: */}
      {display && display.includes('testimonials_rest:') && !display.includes('carousel') && display.includes('single') &&
        (FetchedDataInCarousel(fetchOpts, 'Testimonial'))
      }
      {display && display.includes('testimonials_rest:') && !display.includes('carousel') && !display.includes('single') &&
        <Container maxWidth="md">
          <ContentList fetchOpts={fetchOpts} random={4} colXs={12} colMd={3}
                     spacing={2} justifyContent={`center`}
                     comp={`TestimonialSingle`} />
        </Container>
      }
      {display && display.includes('testimonials_rest:') && display.includes('carousel') &&
        (FetchedDataInCarousel(fetchOpts, 'Testimonial'))
      }

    </Fragment>
  );

  return null;
}

export default ReactRESTView;
